import * as TYPES from "./todoActionTypes";

const defaultState = {
  todos: [],
  count: 0
};

export default function todo(state = defaultState, action) {
  switch (action.type) {
    case TYPES.TODOS:
      return {
        ...state,
        todos: action.value
      };
    case TYPES.TODO_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
