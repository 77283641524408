export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const PROFILE = "PROFILE";
export const BUSINESS = "BUSINESS";
export const DRIVER = "DRIVER";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const ACCESS_TOKENS = "ACCESS_TOKENS";

export const FIREBASE_TOKEN = "FIREBASE_TOKEN";

export const IS_MANANGER = "IS_MANANGER";
export const IS_ADMIN = "IS_ADMIN";

export const PRE_CHECK = "PRE_CHECK";

export const AUTH_BUSY = "AUTH_BUSY";

export const NEED_AUTORIZATION = "NEED_AUTORIZATION";

export const USERS = "USERS";
export const BUCKET_INFO = "BUCKET_INFO";
