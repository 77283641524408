import queryString from "query-string";
import { publicBucket, cookieOpions, osritEnv, URLS } from "../../constants";

const qp = queryString.parse(window.location.search);

const defaultState = {
  authBusy: false,
  isMainApp: !qp.isIframe,
  isQaOnly: osritEnv !== "LIVE",
  baseUrl: URLS.CORE_API,
  publicBucket: publicBucket,
  cookieOpions
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case "SET_IP":
      return {
        ...state,
        ip: action.value
      };
    case "COORDINATES":
      return {
        ...state,
        coords: action.value
      };
    default:
      return state;
  }
}
